.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Slide styles */
.slide {
  width: 300px;
  height: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

/* Option styles */
.optionLabel {
  display: block;
  margin-bottom: 10px;
}

.radio {
  margin-right: 10px;
}

/* Button styles */
.button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.slider {
  width: 100%;
  margin: 20px 0;
  max-height: 100vh;
  overflow-y: auto;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: auto; /* this allows for vertical scrolling */
}

.text-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #0056b3;  
  text-decoration: underline;  
  padding: 0;
}

.centered-container {
  text-align: center;
}


.loading-spinner {
  display: none;
  margin-top: 16px;
  text-align: center;
}

.loading-spinner svg {
  width: 30px;
  height: 30px;
}

.loading-spinner circle {
  stroke-dasharray: 113;
  stroke-dashoffset: 113;
  transform-origin: center;
  animation: spin 1s linear infinite, draw 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes draw {
  0% { stroke-dashoffset: 113; }
  100% { stroke-dashoffset: 0; }
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 10px;
}

.user-image {
  width: 30px; /* or whatever size you want */
  height: 30px;
  border-radius: 50%; /* to make the image circular */
}

.user-email {
  margin-right: 10px;
  font-size: 0.9rem;
}
